@font-face {
  font-family: 'Messina Sans';
  src: url('../fonts/MessinaSans-Regular.woff2') format('woff2'),
      url('../fonts/MessinaSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../fonts/HelveticaNeueThin.woff2') format('woff2'),
      url('../fonts/HelveticaNeueThin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../fonts/HelveticaNeueLight.woff2') format('woff2'),
      url('../fonts/HelveticaNeueLight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../fonts/HelveticaNeueRegular.woff2') format('woff2'),
      url('../fonts/HelveticaNeueRegular.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../fonts/HelveticaNeueMedium.woff2') format('woff2'),
      url('../fonts/HelveticaNeueMedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Messina Sans';
  src: url('../fonts/MessinaSans-Light.woff2') format('woff2'),
      url('../fonts/MessinaSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Rounded';
  src: url('../fonts/SFRounded-Ultralight.woff2') format('woff2'),
      url('../fonts/SFRounded-Ultralight.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Rounded';
  src: url('../fonts/SFRounded-Ultralight.woff2') format('woff2'),
      url('../fonts/SFRounded-Ultralight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Rounded';
  src: url('../fonts/SFRounded-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

:root {
  --bs-body-bg: #000;
  --bs-body-color: #fff;
  --bs-white-rgb: 255, 255, 255;
  --color-white: #fff;
  --font-helvetica: 'HelveticaNeue';
  --font-messina: 'Messina Sans';
  --font-sfpro: 'SF Pro Rounded';
}

* {
  box-sizing: border-box;
}
html.lenis.lenis-smooth {
  scroll-behavior: auto!important;
}
html {
	height: auto;
}
body{
  font-family: var(--font-helvetica);
  font-size: 18px;
  font-weight: 300; 
  background-color: #131313;
  color: var(--color-white);
}
body::-webkit-scrollbar {
  height: 0;
  width: 0
}
h1,h2,h3,h4,h5,h6{
  font-family: var(--font-sfpro);
  font-weight: 100;
  letter-spacing: 0.05px;
  margin-top: 0;
  margin-bottom: 20px;
}
h1,h2{
  font-size: 32px;
  line-height: 38px;
}
h3{
  font-size: 28px;
  line-height: 34px;
}
h4{
  font-size: 22px;
  line-height: 28px;
}
h6{
  font-family: var(--font-messina);
  font-size: 16px;
}
ul {
  margin-top: 0;
  margin-bottom: 0;
}
.list-style-none{
  list-style: none;
}
p{
  margin-top: 0;
  margin-bottom: 15px;
}
p:last-of-type{
  margin-bottom: 0;
}
a {
  text-decoration: none;
}
.text-center{
  text-align: center;
}
.w-100{
  width: 100%;
}
.h-100{
  height: 100%;
}
img{
  max-width: 100%;
  display: block;
}
.overlay-15::before {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #000;
  top: 0;
  left: 0;
  content: "";
  opacity: 15%;
}
.letter-spacing-5 {
  letter-spacing: 0.05em;
}
.letter-spacing-20 {
  letter-spacing: 0.2em;
}
.font-helvetica{
  font-family: var(--font-helvetica);
}
.font-messina,
body .react-datepicker{
  font-family: var(--font-messina);
}
.font-sfpro{
  font-family: var(--font-sfpro);
}

.text-uppercase{
  text-transform: uppercase;
}
.text-white{
  color: #fff;
}
.text-dark,
.text-dark h6,
.text-dark h2,
.text-dark p{
  color: #000;
}
.bg-white {
  background-color: #fff;
}
.font-38{
  font-size: 38px;
  line-height: 46px;
}
.font-26{
  font-size: 26px;
  line-height: 32px;
}
.font-22{
  font-size: 22px;
  line-height: 28px;
}
.font-18{
  font-size: 18px;
  line-height: 35px;
}
.font-14{
  font-size: 14px;
  line-height: 18px;
}
.font-12{
  font-size: 12px;
  line-height: 15px;
}
.fw-100{
  font-weight: 100;
}
.fw-200{
  font-weight: 200;
}
.fw-300{
  font-weight: 300;
}
.fw-400{
  font-weight: 400;
}
.p-0{
  padding: 0;
}
.ptb-200{
  padding-top: 200px;
  padding-bottom: 200px;
}
.ptb-120{
  padding-top: 120px;
  padding-bottom: 120px;
}
.pb-120{
  padding-bottom: 120px;
}
.pb-80{
  padding-bottom: 80px;
}
.pt-50{
  padding-top: 50px;
}
.pt-40{
  padding-top: 40px;
}
.pt-200{
  padding-top: 200px;
}
.plr-100{
  padding-left: 100px;
  padding-right: 100px;
}
.-mt-40{
  margin-top: -40px;
}
.-mt-90{
  margin-top: -90px;
}
.-mt-20{
  margin-top: -20px;
}
.mt-50{
  margin-top: 50px;
}
.mt-200{
  margin-top: 200px;
}
.m-0{
  margin: 0px;
}
.mb-20{
  margin-bottom: 20px;
}
.mb-40{
  margin-bottom: 40px;
}
.flex-gap-20{
  gap: 20px;
}
.position-relative{
  position: relative;
}
.position-absolute{
  position: absolute;
}
.top-0{
  top: 0;
} 
.start-0{
  left: 0;
}
.z-1{
  z-index: 1;
}
.z-2{
  z-index: 2;
}
.overflow-hidden{
  overflow: hidden;
}
.overflowx-hidden{
  overflow-x: hidden;
}
.showonmobile{
  display: none;
}
.hideonmobile {
  display: block;
}
.light_green_dot {
  height: 5px;
  width: 5px;
  background: #A2BB3C;
  display: inline-block;
  border-radius: 50%;
}
.object-fit-cover{
  object-fit: cover;
}

/* Flex Classes */
.d-flex{
  display: flex;
  flex-wrap: wrap;
}
.align-items-center{
  align-items: center;
}
.justify-content-center{
  justify-content: center;
}
.justify-content-between{
  justify-content: space-between;
}
.nowrap{
  flex-wrap: nowrap;
}
.row-reverse{
  flex-flow: row-reverse;
}
.flex-column{
  flex-direction: column;
}
.d-grid{
  display: grid;
}

/* Common Form style */
div .btn-primary,
div .btn-primary:hover,
div .btn-primary:focus,
div .btn-primary:active,
:not(.btn-check)+.btn:active,
.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active{  
  background-color: rgba(var(--bs-white-rgb),0.25);
  border: none;
  font-size: 14px;
  font-family: var(--roboto-font);
  line-height: 18px;
  padding: 19px 20px;
  min-width: 200px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  --bs-bg-opacity: 1;  
  letter-spacing: 0.15em;
  border-radius: 50px;
  text-transform: uppercase;
}
div .btn-primary:hover{
  background-color: rgba(var(--bs-white-rgb),0.35);
}
label.form-label {
  font-weight: 500;
  font-family: var(--roboto-font);
  letter-spacing: 0.02em;
}
.form-check-input[type=radio],
.form-check-input[type=checkbox]{
  display: none;
}
.form-check-input[type=radio] + label,
.form-check-input[type=checkbox] + label{
    background-color: rgba(var(--bs-white-rgb),0.25);
    border: none;
    font-size: 12px;
    font-family: var(--roboto-font);
    line-height: 18px;
    padding: 17px 39px 14px;
    min-width: 200px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    --bs-bg-opacity: 1;
    letter-spacing: 0.15em;
    border-radius: 50px;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
}
.form-check-input[type=radio]:checked + label,
.form-check-input[type=checkbox]:checked + label {
  background: rgba(var(--bs-white-rgb),0.5);
  font-weight: 600;
}
input:focus,textarea:focus{
  outline: none;
}
button{
  cursor: pointer;
}

/* Header */
header {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
header.sticky {
  background: rgba(0,0,0,0.4);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  position: sticky;
  top:0;
  z-index: 9;
}
.header_top {
  width: 100%;
  text-align: center;
  padding: 30px;
  border-bottom: 1px solid rgba(255,255,255,0.5);
  transition: all ease 0.3s;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header.sticky a.header_logo img {
  max-height: 17px;
}
header .header_top nav {
  margin-top: 10px;
  padding: 0 30px px;
}
header .header_top nav > ul {
  margin: 0;
  padding: 0;
}
header .header_top nav > ul > li {
  position: relative;
  display: flex;
  align-items: center;
}
header .header_top nav > ul > li.active::after {
  position: absolute;
  content: "";
  height: 5px;
  width: 5px;
  background: #A2BB3C;
  border-radius: 50%;
  bottom: -18px;
  left: 0;
  right: 0;
  margin: auto;
}
header .header_top nav > ul > li:not(:last-child) {
  margin-right: 40px;
}
header .dropdown_menu {
  width: 170px;
  position: absolute;
  padding: 12px;
  top: 60px;
  background: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.3s;
}
header .header_top nav > ul > li.expanded .dropdown_menu{
  top: 41px;
  opacity: 1;
  visibility: visible;
  z-index: 1;
}
header:not(.sticky) .header_top nav > ul > li.expanded .dropdown_menu {
  top: 30px;
}
header .dropdown_menu ul{
  padding: 0;
}
header .dropdown_menu li:not(:last-child) {
  margin-bottom: 10px;
}
header .dropdown_menu li, header .dropdown_menu li a {
  display: block;
  text-align: left;
}
header .header_top nav > ul > li span.menuicon {
  display: inline-block;
  width: 16px;
  margin-left: 4px;
  cursor: pointer;
}
header .header_top nav > ul > li span.menuicon img{
  transition: all ease 0.3s;
}
header .header_top nav > ul > li.expanded img {
  transform: rotate(180deg);
}
.hamburger {
  display: none;
}
.hamburger .line{
  width: 35px;
  height: 2px;
  background-color: #ecf0f1;
  display: block;
  margin: 6px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger:hover{
  cursor: pointer;
}

.hamburger.is-active .line:nth-child(2){
  opacity: 0;
}
.hamburger.is-active .line:nth-child(1){
  -webkit-transform: translateY(6px) rotate(45deg);
  -ms-transform: translateY(6px) rotate(45deg);
  -o-transform: translateY(6px) rotate(45deg);
  transform: translateY(6px) rotate(45deg);
}
.hamburger.is-active .line:nth-child(3){
  -webkit-transform: translateY(-10px) rotate(-45deg);
  -ms-transform: translateY(-10px) rotate(-45deg);
  -o-transform: translateY(-10px) rotate(-45deg);
  transform: translateY(-10px) rotate(-45deg);
}

/* Mobile Menu */
.mobile_menu_sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  padding: 0 50px 0 0;
  transition: all ease 0.3s;
  transform: translateX(-100%);
}
.mobile_menu_sidebar.show_menu_bar {
  transform: none;
}
.mobile_menu_wrapper {
  padding: 40px;
  background: #181818;
  height: 100%;
  position: relative;
}
.mobile_menu_wrapper nav ul {
  flex-direction: column;
  padding: 0;
  gap: 10px;
}
.mobile_menu_wrapper nav ul li {
  display: flex;
  flex-wrap: wrap;
}
.mobile_menu_wrapper .menuicon {
  display: block;
  width: 18px;
}
.mobile_menu_wrapper .dropdown_menu {
  position: static;
  opacity: 0;
  visibility: hidden;
  height: 0;
  padding: 0;
  width: 100%;
  background: transparent;
}
.mobile_menu_wrapper .expanded .dropdown_menu {
  opacity: 1;
  visibility: visible;
  height: auto;
  padding: 0 0 0 10px;
}
.close_menu_bar {
  position: absolute;
  top: 20px;
  right: 20px;
  width: auto;
  height: auto;
  cursor: pointer;
}
.close_menu_bar .line {
  display: block;
  width: 35px;
  height: 2px;
  background: #fff;
  margin: 5px 0;
}
.close_menu_bar .line:nth-child(1){
  -webkit-transform: translateY(7px) rotate(45deg);
  -ms-transform: translateY(7px) rotate(45deg);
  -o-transform: translateY(7px) rotate(45deg);
  transform: translateY(7px) rotate(45deg);
}
.close_menu_bar .line:nth-child(2){
  -webkit-transform: translateY(-0px) rotate(-45deg);
  -ms-transform: translateY(-0px) rotate(-45deg);
  -o-transform: translateY(-0px) rotate(-45deg);
  transform: translateY(-0px) rotate(-45deg);
}

/* Home */
.page_banner picture img{
  height: 100vh;
  object-fit: cover;
  width: 100%;
  min-height: 600px;
  object-position: bottom center;
}
.main_home_banner::before {
  position: absolute;
  left: 0;
  content: "";
  width: 100%;
  top: 0;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.65) 10%, rgba(0, 0, 0, 0) 65%, #131313);
  top: 0;
}
.main_home_banner_content {
  top: 0;
  left: 0;
  padding-top: 158px;
}
.bannerbox {
  position: absolute;
  height: calc(100% - 100px);
  max-width: 415px;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  box-shadow: 0px 22px 70px 10px #000000d9;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.home_banner_bottom {
  margin: 0 -50%;
  padding: 0 26%;
}
.dotdivider span {
  height: 5px;
  width: 5px;
  background: #A2BB3C;
  display: inline-block;
  border-radius: 50%;
  margin: 35px 0;
}
.dotdivider.big span {
  height: 10px;
  width: 10px;
}
.slider_wrapper .swiper {
  padding: 66px 0 130px;
}
.featured_work_slider .swiper-slide{
  transition: all ease 0.3s;
  position: relative;
  z-index: 1;
}
.featured_work_slider  .swiper-slide.swiper-slide-active {
  transform: scale(1.2);
  position: relative;
  z-index: 2;  
}
.featured_work_slider  .swiper-slide.swiper-slide-active:hover {
  transform: scale(1.25);
}
.common_work_slide {
  height: 500px;  
}
.work_slide_hover_content {
  bottom: 0;
  width: 100%;
  padding: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: none;
}
.featured_work_slider .swiper-slide .common_work_slide > img {
  opacity: 35%;
  object-fit: cover;
}
.featured_work_slider .swiper-slide.swiper-slide-active .common_work_slide > img {
  opacity: 100%;
}
.swiper-slide.swiper-slide-active .common_work_slide_text::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: #000;
  opacity: 50%;
  top: 0;
  left: 0;
  z-index: -1;
}
.common_work_slide_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 2;
  justify-content: center;
}
.featured_work_slider .swiper-slide.swiper-slide-active .common_work_slide_text {
  /* padding: 105px 0px 155px; */
  padding: 0 10px 24px;
}
.featured_work_slider .swiper-slide.swiper-slide-active .common_work_slide_text .workbox_head {
  margin: auto 0 0;
}
.workbox_head {
  margin: 0 -40px;
}
.common_work_slide h2 {
  font-size: 28px;
  line-height: 32px;
}
.common_work_slide_text  p {
  font-size: 10px;
  text-transform: uppercase;
}
.featured_work_slider .swiper-slide.swiper-slide-active .common_work_slide_text .work_slide_hover_content {
  display: flex;
}
.featured_work_slider .swiper-slide.swiper-slide-active svg.showdefault {
  display: none;
}
.common_work_slide svg.showdefault {
  margin-top: 30px;
}
.common_work_slide svg.showonhover {
  margin-bottom: 28px;
}
.common_slider_arrow {
  height: 45px;
  width: 45px;
  flex: 0 0 45px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid rgba(255,255,255,0.5);
  cursor: pointer;
}
.slider_nav {
  margin: 20px 0;
  gap: 40px;
}
.slider_scrollbar.swiper-scrollbar-horizontal {
  height: 2px;
  width: 100%;
  max-width: 245px;
}
.slider_scrollbar.swiper-scrollbar-horizontal {
  height: 2px;
  max-width: 245px;
  background: rgba(245,245,245,0.3);
}
.slider_scrollbar .swiper-scrollbar-drag {
  background: rgba(245,245,245,0.75);
}
.project_link {
  margin-bottom: -40px;
  position: absolute;
  bottom: -12px;
  display: none;
}
.featured_work_slider .swiper-slide.swiper-slide-active .project_link{
  display: flex;
}
.underline_link::after {
  width: 65px;
  height: 0.5px;
  background: #fff;
  content: "";
  display: block;
  margin: 7px 0 0;
}

/* Home Gallery */
.home_gallery_wrapper{
  max-width: 910px;
  margin: 0 auto;
}
.home_gallery_image img, .home_gallery_image video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
}
.home_gallery_middle{
  margin-top: -245px;
  gap: 10px;
}
.home_gallery_top .home_gallery_image.gallery_image_1 {
  width: 316px;
  height: 444px;
  margin: auto;
}
.home_gallery_image.gallery_image_1::after {
  position: absolute;
  content: "";
  height: 85%;
  background: linear-gradient(00deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  width: 100%;
  bottom: -0.5px;
}
.gallery_image_2 {
  width: 100%;
  max-width: 330px;
  height: 313px;  
}
.gallery_image_3{
  width: 100%;
  max-width: 375px;
  height: 282px;
  margin-top: 183px;
}
.gallery_image_4 {
  width: 152px;
  height: 213px;
  margin-left: 280px;
  margin-top: 0px;
}
.home_gallery_image  .viewalllink {
  bottom: 40px;
  z-index: 1;
  left: 30px;
}
.home_gallery_image .viewalllink::after {
  width: 95px;
  margin: 10px 0 0;
}

/* Service Slider */
.common_service_slide {
  height: 620px;
  opacity: 20%;
}
.common_service_slide img{  
  object-fit: cover;
}
.swiper-slide-active .common_service_slide{
  opacity: 100%;
}
.common_service_slide_text {
  padding: 100px 40px 40px;
}
.common_service_slide_text::after {
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
  top: 0;
  left: 0;
  z-index: -1;
  content: "";
}
.common_service_slide_text  h2 {
  margin-bottom: 35px;
  font-size: 35px;
}
.common_service_slide_text ul.list-style-none li:not(:last-child) {
  margin-bottom: 23px;
  letter-spacing: 0.05px;
}
.common_service_slide_text  ul.list-style-none {
  display: none;
}
.swiper-slide.swiper-slide-active .common_service_slide_text  ul.list-style-none {
  display: block;
}
.services_slider > h6 {
    margin-bottom: 56px;
}
.services_slider .slider_nav {
    margin-top: 35px;
}
.common_service_slide_text {
  padding: 100px 30px;
}
.follow_media_grid{
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
}
.follow_milkbar_top_inner {
  gap: 30px;
  margin-bottom: 40px;
  padding: 0 100px;
  justify-content: left;
}
a.follow_button {
  padding: 10px 28px 10px 28px;
  border-radius: 25px;
  background: rgba(255,255,255,0.1);
  color: #fff;
  border: 0.5px solid #575757;
  font-size: 14px;
  letter-spacing: 0.05px;
  display: inline-block;
}
.follow_milkbar_bottom .swiper-slide {
  transition: all ease 0.3s;
}
.follow_milkbar_bottom .swiper-slide.swiper-slide-active + .swiper-slide + .swiper-slide + .swiper-slide + .swiper-slide + .swiper-slide,
.follow_milkbar_bottom .swiper-slide.swiper-slide-active {
  opacity: 0.3;
}

/* About Page */
.inner_page_banner::before,
.inner_page_banner::after{
  position: absolute;
  content: "";
  width: 100%;
  left: 0;
}
.inner_page_banner::before{
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
  height: 30%;
  top: 0;    
}
.inner_page_banner::after {
  background: linear-gradient(0deg, rgb(19 19 19) 10%, rgba(0, 0, 0, 0) 100%);
  height: 50%;
  bottom: 0;
}
.about_content {
  max-width: 750px;
  margin: -180px auto 0;
  padding: 0 15px;
}

/* contact page */
.contact_form_wrap {
  max-width: 900px;
  margin: -180px auto 0;
}
.option_types_radios {
  max-width: 630px;
  margin: auto;
  gap: 12px;
}
.contact_form_wrap .form-group {
  text-align: center;
  margin-bottom: 45px;
}
.contact_form_wrap .form-group.textareafld {
  padding: 40px 0;
}
.option_types_radios input[type=checkbox] + label {
  padding: 20px 45px 17px;
  font-size: 18px;
  display: inline-block;
  font-family: 'SF Pro Rounded';
  text-transform: inherit;
  letter-spacing: 0.05px;
  min-width: auto;
  height: auto;
  background: rgba(255,255,255,0.10);
}
.contact_form_wrap .form-group input, .contact_form_wrap .form-group textarea {
  max-width: 360px;
  width: 100%;
  background: transparent;
  border-width: 0 0 0.6px 0;
  font-size: 18px;
  font-family: 'SF Pro Rounded';
  letter-spacing: 0.05px;
  margin: 0 auto;
  color: #fff;
}
.contact_form_wrap .form-group input[type="text"],
.contact_form_wrap .form-group input[type="email"] {
  height: 46px;
  vertical-align: top;
  line-height: 1;
  text-align: center;
  padding-bottom: 25px;
  color: #fff;
  font-size: 18px;
  font-family: 'SF Pro Rounded';
}
.contact_form_wrap .form-group textarea{
  height: 120px;
}
.contact_form_wrap  button {
  background: transparent;
  border: none;
  font-size: 16px;
}
.contact_form_wrap  button::after {
  width: 95px;
  margin: 10px 0 0;
}
.message  p.font-18 {
  margin: 5px 0 10px;
}

/* Project Page */
.file-upload ::file-selector-button {
 background-color: transparent;
 border: none;
}
.file-upload .i-pic-upload {
  position: absolute;
  color: #273142;
  outline: none;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  width: 15px;
  height: 15px;
  border-radius: 0px 3px 3px 0px;
  pointer-events: none;
}
.form-login .add_field {
  width: calc(33.33% - 30px);
}
.add_field input {
  padding: 15px 15px;
  width: 100%;
  border: none;
  outline: none;
  border-radius: 4px;
  background: #fff;
  height: 50px;
  line-height: normal;
  font-size: 15px;
  color: #000;
}
.add_project button {
  background: #fff;
  border: 1px solid transparent;
  border-radius: 0;
  color: #000;
  display: inline-block;
  font-size: 15px;
  letter-spacing: .05px;
  padding: 10px 15px;
  max-width: fit-content;
  font-weight: 600;
}
.project_banner .bannerbox {
  align-items: flex-end;
  padding-bottom: 60px;
}
.project_banner .bannerbox .home_banner_bottom {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.project_banner .bannerbox .home_banner_bottom h1 {
  margin-bottom: 33px;
}
.brand_direction {
  max-width: 510px;
  margin: 80px auto 0;
  padding: 0 15px;
}
.brand_direction  p {
  font-size: 18px;
  line-height: 30px;
}
.brand_direction  .brand_logo {
  margin-top: 110px;
  gap: 30px
}
.common_brand_logo:not(.active) {
  opacity: 40%;
}
.seamless_booking_head{
  max-width: 460px;
  margin: 0 auto;
}
.dotdivider.mtb15 span {
  margin: 15px 0;
}
.seamless_booking_slider_wrapper .swiper {
  padding: 50px 0;
}
.seamless_booking_slider_wrapper .swiper-slide {
  transition: all ease 0.3s;
  position: relative;
  z-index: 1;
}
.seamless_booking_slider_wrapper .swiper-slide.swiper-slide-active {
  transform: scale(1.2);
  position: relative;
  z-index: 2;
}
.more_project_slider .swiper-slide.swiper-slide-active .common_work_slide_text {
  padding-bottom: 40px;
  justify-content: flex-end;
}
.more_project_slider .swiper-slide.swiper-slide-active .common_work_slide_text::after {
  height: 40%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
  top: auto;
  bottom: 0;
}
.project_verticle_images{
  max-width: 910px;
  padding: 0 15px;
  margin: 0 auto;
  grid-template-columns: repeat(2, 1fr);
  gap: 0;
}
.project_verticle_images .project_verticle_image:first-child{
  padding-right: 140px;
}
.project_verticle_images .project_verticle_image:last-child {
  padding-left: 140px;
  margin-top: 215px;
}
.verticle_overlap_text {
  background-color: rgba(0,0,0,0.3);
  box-shadow: 0px 4px 4px 0px #00000040;
  max-width: 270px;
  border-radius: 5px;
  padding: 26px 30px;
}
.project_verticle_images .project_verticle_image:first-child .verticle_overlap_text{
  top: 80px;
  right: 0px;
}
.project_verticle_images .project_verticle_image:last-child .verticle_overlap_text{
  top: 230px;
  left: 0px;
}
.verticle_overlap_text p {
  color: #fff;
  font-size: 16px;
  line-height: 20px;
}
.project_verticle_image  img {
  border-radius: 10px;
  overflow: hidden;
}


/* Social Media */
.social_media_page .gallery_content {
  max-width: 550px;
  opacity: 0;
  position: absolute;
  z-index: 22;
}
.social_media_page .home_gallery_image_sec {
  width: 100%;
}
.social_media_page .gallery_content1 {
  left: -90%;
  width: 100%;
  min-width: 400px;
  bottom: 12px;
}
.social_media_page .gallery_image_2 {
  height: 329px;
}
.social_media_page .gallery_image_4 {
  margin-top: -118px;
  height: 267px;
}
.social_media_page .home_gallery_middle .home_gallery_image_sec:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%);
  z-index: 1;
  pointer-events: none;
}
.social_media_page .gallery_content h4 {
  margin: 0 0 8px;
}
.social_media_page .gallery_content2 {
  width: 100%;
  text-align: right;
  right: 85%;
  top: 205px;
  min-width: 424px;
}
.social_media_page .gallery_image_3 {
  height: 500px;
  max-width: 400px;
  margin-top: 0;
}
.social_media_page .page_banner picture img {
  object-position:  top center;
}
.social_media_page .main_home_banner::before {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%);
  height: 491px;
  pointer-events: none;
}
.social_media_page .main_home_banner::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 500px;
  bottom: 0;
  background: linear-gradient(180deg, rgba(19, 19, 19, 0.00) 31%, #131313 100%);
  pointer-events: none;
}
.social_media_page .text_block p {
  font-family: var(--font-messina);
  line-height: 30px;
}
.social_media_page .text_block {
  max-width: 561px;
}
.whatwedo-list {
  list-style: none;
  display: grid;
  gap: 25px;    padding: 0;
}
.whatwedo-list li {
  letter-spacing: 0.9px;
  text-transform: lowercase;
}
.brand_page .what_we_do {
  margin: -60px 0 0;
  padding: 0 0 180px;
}
.single-project-banner-bottm {
  gap: 40px;
  margin-top: -80px;
  z-index: 1;
}
.social-media-slider h6 {
  margin: 0;
}
.social-media-slider {
  padding: 183px 0 148px;
}
.social-media-slider p {
  line-height: 30px;
}
.social-media-slider .common_service_slide {
  opacity: 1;
  height: calc(442px - 126px);
  margin: 63px 0;
}
.social-media-slider .swiper-slide-active .common_service_slide {
  height: 535px;
  margin: 0;
}
.social-media-slider .common_service_slide .single-brand-sec {
  background: rgba(0, 0, 0, 0.20);
  box-shadow: 1.167px 1.167px 17.5px 23.333px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(8.750000953674316px);
  width: calc(100% - 66px);
  height: calc(100% - 66px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 33px;
}
.social-media-slider .common_service_slide>img{
  position: absolute;
}
.social-media-slider .swiper-slide-active .common_service_slide>img{
  height: calc(100% - 93px);
}
.social-media-slider .swiper-slide-active .common_service_slide .single-brand-sec {
  width: calc(100% - 76px);
  height: calc(100% - 76px - 93px);
  margin: 38px;
}
.social-media-slider .common_service_slide .single-brand-sec img {
  max-width: 175px;
}
.social-media-slider .swiper-slide-active .common_service_slide .single-brand-sec img {
  max-width: 100%;
}
.social-media-slider .common_slider_arrow {
  display: none;
}
.social-media-slider.services_slider .slider_nav {
  margin-top: 64px;
}
.social-media-slider .slider_scrollbar.swiper-scrollbar-horizontal {
  max-width: 225px;
}
.social-media-slider .swiper-slide-active .common_service_slide .social-media-slider-info {
  opacity: 1;
}
.social-media-slider .common_service_slide .social-media-slider-info {
  margin: 0 0 35px;
  opacity: 0;
}
.social-media-steps-wraper {
  padding: 93px 154px 200px 115px;
}
.social-media-steps {
  background: #E6E5DD;
}
.social-media-steps-wraper {
  padding: 93px 154px 200px;
  counter-reset: counter;
}
.social-media-steps {
  background: #E6E5DD;
}
.social-steps {
  padding: 0 16px;
}
.step-box {
  border: 0.5px solid rgba(0, 0, 0, 0.20);
  background: rgba(230, 229, 221, 0.50);
  backdrop-filter: blur(5px);
  color: var(--bs-body-bg);
  max-width: 300px;
  width: 100%;
  margin-left: 16px;
}
.step-box:before {
  counter-increment: counter;
  content: counter(counter) ".";
  position: absolute;
  top: 34px;
  left: -31px;
  font-family: 'SF Pro Rounded';
  font-size: 20px;
  font-style: italic;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 1px;
}
.step-box-top {
  padding: 36px 48px 27px 38px;
  border-bottom: .5px solid rgba(0, 0, 0, 0.20);
}
.step-box-botm {
  padding: 20px 38px;
}
.step-box-botm ul {
  padding: 0;
  display: grid;
  gap: 5px;
}
.step-box-botm li {
  position: relative;
  padding: 0 0 0 18px;
  line-height: 28px; 
  letter-spacing: 0.6px;
}
.step-box-botm li:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
  background: #A2BB3C;
  border-radius: 50%;
}
.step-box-img img {display: inline-block;}
.step-box-img {
    text-align: center;
}
.social-steps:first-child .step-box-img {
  margin-top: -40px;
}
.social-steps:last-child {
  padding-left: 70px;
}
.envisioning_content{pointer-events: none;}
.envisioning_content .underline_link{pointer-events: auto;}
.text_block{
  max-width: 690px;
  padding: 0 20px;
  margin: 0 auto;
}
.common_social_slider {
  display: flex;
  flex-wrap: wrap;
  padding-top: 150px;
}
.common_social_slider > div{
  flex: 0 0 50%;
  max-width: 50%;
}
.social_slider_slide {
  height: 500px;
  border-radius: 6px;
  overflow: hidden;
}
.rtlSlider-swiper-custom-scrollbar {
  direction: rtl;
}
.common_social_slider .slider_scrollbar.swiper-scrollbar-horizontal {
    max-width: 225px;
    margin-top: 45px;
}
.rtlSlider-swiper-custom-scrollbar.slider_scrollbar {
  margin-left: auto;
}
.social_ltrslider_outer .social_ltrslider_left,
.social_rtlslider_outer .social_rtlslider_left {
  padding: 8% 9% 0 9%;
}
.common_social_slider p {
  font-size: 18px;
  line-height: 25px;
}
/* .envisioning {
  margin-top: 150px;
} */
.social_media_page h6.workhead {
  text-align: center;
  padding: 0 20px;
  margin: 100px 0 0 0;
  display: none;  
}

/* Work Page */
.project_list_wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 40px;
  column-gap: 10px;
}
.project_list_wrapper .project_link {
    display: block;
}
.project_list_wrapper > div {
  margin-bottom: 60px;
}
.project_list_wrapper .common_work_slide::after {
  position: absolute;
  content: "";
  height: 100%;
  width: calc(100% - 80px);
  background: #000;
  opacity: 50%;
  top: 0;
  left: 40px;
}
.project_list_wrapper .common_work_slide {
  padding: 0 40px;
}
.project_list_wrapper .common_work_slide img {
  width: calc(100% - 80px);
  left: 40px;
}
.project_list_wrapper .common_work_slide_text {
  left: 0;
}
.project_list_wrapper .workbox_head {
  margin: 0;
}


 /* Service Tabs Section */
 .services_tabs_panel {
  padding-left: 100px;
}
.service_tabs_wrapper {
  display: flex;
  flex-wrap: wrap;
}
.service_tab_images, .service_tab_items {
  flex: 0 0 auto;
  width: 50%;
}
.service_tab_images {
  padding: 0 0 0 30px;
}
.service_tab_items {
  display: flex;
  align-items: center;
}
.service_tabs_wrapper .swiper.swiper-vertical {
  height: auto;
  margin: 0;
}
.service_tabs_wrapper .swiper-slide {
  height: auto !important;
}
.service_tab_images .swiper-slide {
  display: flex;
  align-items: center;
}
.service_tab_item {
  display: flex;
  column-gap: 50px;
}
.service_desc {
  display: flex;
  gap: 20px;
  max-width: 300px;
}
.service_desc .icon {
  display: block;
  width: 50px;
  height: auto;
}
.service-name h3 {
  font-size: 30px;
  line-height: 30px;
  transition: all ease 0.3s;
  opacity: 0.6;
  font-weight: 200;
}
.service_desc {
  display: flex;
  gap: 20px;
  max-width: 300px;
  transition: all ease 0.3s;
  opacity: 0;
  visibility: hidden;
}
.service_tab_item.active .service_desc {
  opacity: 1;
  visibility: visible;
}
.service_desc .text strong {
  font-weight: 500;
}
.swiper-slide-thumb-active .service_desc {
  opacity: 1;
  visibility: visible;
}
.swiper-slide-thumb-active .service-name h3 {
    opacity: 1;
}
.services_tabs_panel .slider_nav {
  display: none;
}
.swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden.mySwiper {
  width: 100% !important;
}
.mobile_tab_content {
  width: 100%;
  height: 540px;
}

.mobile_tab_content .wrapper {
  padding: 60px 30px 20px;
  height: 100%;
  position: relative;
  z-index: 1;
}
.mobile_tab_content .tab_content_heading h3 {
  margin: 0 0 30px;
}
.mobile_tab_content .tab_content_wrapper ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.mobile_tab_content .tab_content_wrapper ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 20px;
}
/* .service_tab_images .swiper-slide img {
  opacity: 0;
}
.service_tab_images .swiper-slide.swiper-slide-active img {
  opacity: 1;
} */


/* Footer */
.site_footer {
  padding: 100px 0 250px;
  position: relative;
}
.site_footer:after {
  content: '';
  position: absolute;
  top: 190px;
  width: 100%;
  height: calc(100% - 190px);
  background: linear-gradient(179deg, #131313, transparent);
}
.footer_bg_image {
  position: absolute;
  top: auto;
  height: calc(100% - 200px);
  width: 100%;
  bottom: 0;
}
.footer_bg_image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.footer_wrapper {
  padding: 0 100px;
  gap: 50px;
  position: relative;
  z-index: 5;
}
.footer_menu h3.menu_title {
  font-size: 12px;
  line-height: 18px;
  margin: 0 0 40px;
  letter-spacing: 0.10em;
  text-transform: uppercase;
}
.footer_menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.footer_menu ul li a {
  color: #fff;
  font-family: 'SF Pro Rounded';
  font-weight: 100;
  letter-spacing: 0.05em;
  opacity: 0.6;
  transition: all ease 0.3s;
}
.footer_menu ul li.active a,
.footer_menu ul li:hover a {
  opacity: 1;
}

/* admin login page */

input:-webkit-autofill {
    -webkit-box-shadow:0 0 0 50px #121212 inset; 
    -webkit-text-fill-color: #fff;
}

input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px #121212 inset;/*your box-shadow*/
    -webkit-text-fill-color: #fff;

} 
.login-title {
  text-align: center;
  margin: 0;
}
#login-page {
    background: #121212;
    padding: 50px 0;
    display: flex;
    height: 100vh;
}
.notice {
  font-size: 13px;
  text-align: center;
  color: #666;
  margin-bottom: 30px;
}
.login {
    width: 100%;
    height: auto;
    background: #FFF;
    padding: 40px;
    max-width: 400px;
    margin: auto;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 30px;
}
.login a {
  margin-top: 25px;
  text-align: center;
}
.form-login {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  gap: 30px;
  margin-bottom:30px;
}
#login-page .form-login{
  gap: 0;
  margin-bottom:0px;
}
.form-login label {
    text-align: left;
    font-size: 16px;
    margin-top: 10px;
    margin-left: 15px;
    display: block;
    color: #000;
    margin-bottom: 5px;
    font-weight: 600;
    letter-spacing: 0;
}
.input-email, .input-password {
    width: 100%;
    background: #121212;
    border-radius: 25px;
    margin: 4px 0 15px 0;
    padding: 15px 12px;
    display: flex;
    color: #fff;
}
.form-login i.icon {
    color: #fff;
}
.icon {
  padding: 4px;
  color: #666;
  min-width: 30px;
  text-align: center;
}
.form-login input[type="email"],
.form-login input[type="password"] {
  width: 100%;
  border: 0;
  background: none;
  font-size: 15px;
  padding: 0;
  color: #fff;
  outline: none;
}
.form-login label input[type="checkbox"] {
    cursor: pointer;
}
.form-login input::placeholder{
  color: #fff;
}
.form-login button[type="submit"] {
    width: 100%;
    border: 0;
    border-radius: 25px;
    padding: 18px 14px;
    background: #121212;
    color: #FFF;
    display: inline-block;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
    transition: ease all 0.3s;
}
.form-login button[type="submit"]:hover {
  opacity: 0.9;
}


/* admin Dasboard */
.dashboard-nav {
  background: #181818;
  display: inline-block;
  position: fixed;
  top: 0px;
  left: 0;
  height: 100vh;
  width: 220px;
  max-width: 100%;
  z-index: 10;
  overflow: auto;
}

.dashboard-nav .logo a{
color: #fff;
display: block;
text-decoration: none;
text-align: center;
padding: 20px 0;
transition: all 200ms ease;
background: #121212;
}

.dashboard-nav .logo .title{
display: block;
font-size: 1em;
}
.dashboard-nav .logo .fa{
font-size: 68px;
}

.main-nav{
margin: 0;
padding: 20px 0;
list-style: none;
}
.main-nav a span {
  margin-right: 5px;
}
.main-nav a{
display: block;
color: #fff;
text-decoration: none;
text-transform: capitalize;
font-size: 0.875em;
padding: 1em 0 1em 20px;
transition: background 200ms ease;
}
.main-nav a:hover {
  background: #374151;
}
.dashboard {
  width: calc(100% - 220px);
  margin-left: auto;
}
.dashboard .container {
  padding: 24px;
  background: #000;
  height: 100vh;
  overflow: auto;
}
.dash_title {
  padding-bottom: 30px;
}

.projects {
  background-color: #121212;
  border: 1px solid #121212;
  width: 100%;
  height: 100vh;
  border-radius: 20px;
}

.projects-header {
  color: white;
  padding: 22px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.project_search {
  display: none;
  align-items: center;
  gap: 20px;
}
.project_search input {
  border: none;
  outline: none;
  border-radius: 30px;
  padding: 10px 15px;
  line-height: 1;
  font-size: 12px;
  color: #111827;
}
.project_search input::placeholder{
color: #111827;
}
.project_search a.btn {
  text-decoration: none;
  color: #111827;
  background: #fff;
  border: 1px solid transparent;
  display: inline-block;
  font-size: 12px;
  padding: 10px 15px;
  line-height: 1;
  border-radius: 30px;
}
.project_search form {
  display: flex;
  align-items: center;
  gap: 6px;
}
.add_project a {
  background: #fff;
  border: 1px solid transparent;
  border-radius: 0;
  color: #000;
  display: inline-block;
  font-size: 15px;
  letter-spacing: .05px;
  padding: 10px 15px;
  max-width: fit-content;
  font-weight: 600;
}
.view_detail {
  text-decoration: none;
  width: 100%;
  background: #ffffff1a;
  border: 1px solid transparent;
  border-radius: 25px;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  letter-spacing: .05px;
  padding: 10px 28px;
  max-width: fit-content;
}

.count {
    padding-left: 10px; /* Adjust space as needed */
}
.title {
font-size: 21px;
display: flex;
align-items: center;
}

.count {
color: #fff;
font-size: 16px;
}	
.title {
font-size: 21px;
}
.projects-inner {
  background: #1f2937;
  padding: 25px;
  border-radius: 10px;
}
.projects-table {
  width: 100%;
  border-spacing: 10px;
}
.projects-table td,
.projects-table th {
color: white;
padding: 10px 22px;
vertical-align: middle;
text-align: center;
border-radius: 10px;
border-bottom: 1px solid;
}

.projects-table td p {
font-size: 15px;
margin: 0;
}

.projects-table th {
  background-color: #374151;
  font-weight: 500;
  border-radius: 10px;
}
.projects-table tr:nth-child(even) {
  background: #374151;
}
.brand_sec_wrap {
  width: calc(100% - 220px);
  margin-left: auto;
}
.brand_container, 
.add_project_main {
  background: #1f2937;
  padding: 25px;
  border-radius: 10px;
}
.add_project_main .add_field h2 {
  font-size: 18px;
  margin: 0 0 10px;
  font-weight: 500;
  line-height: normal;
}
.brand_inner_wrap {
  padding: 24px;
  background: #000;
  height: 100vh;
  overflow: auto;
}
.brand_grid {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}
.brand_grid .brand_item {
  color: #ffffff;
  text-align: left;
  background: #374151;
  box-shadow: none;
  min-width: inherit;
  padding: 20px;
  border-radius: 10px;
}
.add_booking {
  background: #fff;
  border: 1px solid transparent;
  border-radius: 0;
  color: #000;
  display: inline-block;
  font-size: 15px;
  letter-spacing: .05px;
  padding: 10px 15px;
  max-width: fit-content;
  font-weight: 600;
}
.brand_image_wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.brand_img_item {
  position: relative;
  padding-bottom: 102%;
}
.brand_img_item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.brand_container button, 
.form_submit_btn button {
  background: #fff;
  border: 1px solid transparent;
  border-radius: 0;
  color: #000;
  display: inline-block;
  font-size: 15px;
  letter-spacing: .05px;
  padding: 10px 15px;
  max-width: fit-content;
  font-weight: 600;
}
.brand_edit_btn {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
  justify-content: flex-end;
}
.brand_form_wrap {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
}
.brand_form_wrap .form_item {
  width: 33.33%;
  padding-left: 20px;
  padding-bottom: 20px;
}
.brand_form_wrap .form_item input {
  width: 100%;
  padding: 15px 20px;
  outline: none;
  border: none;
  border-radius: 10px;
  background: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  color: #000;
}
.brand_form_wrap .form_item label {
  display: block;
  font-size: 18px;
  margin: 0 0 10px;
  font-weight: 500;
}
.form_submit_btn {
  width: 100%;
  padding-left: 20px;
}
input[type=file]::-webkit-file-upload-button {
  display: block;
  width: 0;
  height: 0;
background-color: transparent;
padding: 0;
outline: none;
border: none;
}

.back_btn_wrap .back-btn {
  background: #fff;
  border: 1px solid transparent;
  border-radius: 0;
  color: #000;
  display: inline-block;
  font-size: 15px;
  letter-spacing: .05px;
  padding: 10px 15px;
  max-width: fit-content;
  font-weight: 600;
}
.back_btn_wrap {
  text-align: right;
  margin-bottom: 30px;
}
.project_detail_wrap .detail_item_inner, 
.booking_details_wrap .detail_item_inner {
  text-align: left;
  background: #374151;
  box-shadow: none;
  min-width: inherit;
  padding: 20px;
  border-radius: 10px;
}
.project_detail_wrap .detail_item h2, 
.booking_details_wrap .detail_item h2 {
  margin: 0 0 15px;
  font-size: 20px;
  line-height: normal;
  text-transform: capitalize;
  font-weight: 600;
}
.project_detail_wrap .detail_item_inner p, 
.booking_details_wrap .detail_item_inner p {
  font-size: 15px;
  line-height: normal;
}
.project_detail_wrap .detail_item, 
.booking_details_wrap .detail_item {
  margin: 0 0 35px;
}
.project_detail_wrap .gallery_inner {
  background: #fff;
  border-radius: 0;
}
.gallery_inner .detail_img_item {
  position: relative;
  padding-bottom: 76%;
}
 .gallery_inner .detail_img_item img {
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.project_detail_wrap .gallery_inner .detail_img_grid {
  grid-template-columns: repeat(6, 1fr);
}
.detail_banner_wrap .detail_img_item {
  background: #fff;
  padding: 20px;
}
.detail_banner_wrap .detail_img_item h2 {
  color: #000;
}
.banner_img_wrap {
  position: relative;
  padding-bottom: 56%;
}
.banner_img_wrap img {
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.detail_project_btn_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 35px;
}
.detail_edit_del .detail_btns {
  background: #fff;
  border: 1px solid transparent;
  border-radius: 0;
  color: #000;
  display: inline-block;
  font-size: 15px;
  letter-spacing: .05px;
  padding: 10px 15px;
  max-width: fit-content;
  font-weight: 600;
}
.detail_edit_del {
  display: flex;
  align-items: center;
  gap: 10px;
}
.update_detail_wrap .back-btn {
  background: #fff;
  border: 1px solid transparent;
  border-radius: 0;
  color: #000;
  display: inline-block;
  font-size: 15px;
  letter-spacing: .05px;
  padding: 10px 15px;
  max-width: fit-content;
  font-weight: 600;
}
.update_details_wrap .form-group {
  margin: 0 0 35px;
}
.update_details_wrap .form-group label {
  display: block;
  margin: 0 0 15px;
  font-size: 20px;
  line-height: normal;
  text-transform: capitalize;
  font-weight: 600;
}
.update_details_wrap .form-group input {
  width: 100%;
  padding: 15px 20px;
  outline: none;
  border: none;
  border-radius: 10px;
  background: #fff;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  color: #000;
}
.update_details_wrap .form-group input[type="file"] {
  width: fit-content;
  padding: 10px 20px;
}
.update_details_wrap .gallery_inner {
  background: #fff;
  box-shadow: none;
  padding: 20px;
}
.detail_image_item {
  text-align: right;
}
.update_details_wrap {
  background: #1f2937;
  padding: 25px;
  border-radius: 10px;
}
.booking_detail_img_grid .detail_img_item img {
  max-width: 200px;
}
.update_booking_detail .upload_item {
  margin: 0 0 20px;
}
/* admin dashboard main */

        .content .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
        }
        .content .header button {
            padding: 10px 20px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
        }
        .content .header .btn-primary {
            background-color: #007bff;
            color: #ffffff;
        }
        .content .header .btn-secondary {
            background-color: #f1f1f1;
            color: #333;
        }
        .main-section_dashboard .cards {
          display: grid;
          margin-bottom: 20px;
          grid-template-columns: repeat(4, 1fr);
          gap: 20px;
      }
        .card i {
            font-size: 30px;
            margin-bottom: 10px;
        }
        .main-section_dashboard {
          background: #1f2937;
          padding: 25px;
          border-radius: 10px;
      }
      .main-section_dashboard .card {
        color: #ffffff;
        text-align: left;
        background: #374151;
        box-shadow: none;
        min-width: inherit;
        padding: 20px;
        border-radius: 10px;
    }
    .main-section_dashboard .card .number {
      font-size: 26px;
      font-weight: 500;
      margin-bottom: 8px;
  }
  .main-section_dashboard .card .label {
    font-size: 14px;
    font-weight: 300;
    color: #fff;
}
.main-section_dashboard .user-info {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
.main-section_dashboard .user-info .details {
  background: #374151;
  padding: 25px;
  border-radius: 10px;
}
        .user-info img {
            border-radius: 50%;
            margin-right: 10px;
        }
        .user-info .details {
            flex: 1;
        }
        .user-info .details .name {
            font-weight: 500;
            text-transform: capitalize;
        }
        .user-info .details .handle {
            color: #888;
        }
        .main-section_dashboard .user-info .details .tweet {
          margin-top: 10px;
          font-size: 14px;
          line-height: 1.4em;
          color: #fff;
      }
      .update_details_wrap .detail_img_item {
        text-align: right;
    }
    .update_details_wrap .remove_btn {
      background: #1f2937;
      border: none;
      color: #fff;
      padding: 5px 10px;
      border-radius: 10px;
      font-weight: 600;
      line-height: normal;
      font-size: 11px;
      margin-top: 10px;
  }
  .detail_banner_wrap .detail_img_grid {
    grid-template-columns: repeat(2, 1fr);
}
.update_details_wrap .detail_banner_wrap .detail_img_grid {
  display: flex;
  flex-direction: column;
}
.update_details_wrap .detail_banner_wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.update_details_wrap .detail_banner_wrap .form-group {
  margin: 0;
}
.update_btn_wrap .update_btn {
  background: #fff;
  border: 1px solid transparent;
  border-radius: 0;
  color: #000;
  display: inline-block;
  font-size: 15px;
  letter-spacing: .05px;
  padding: 10px 15px;
  font-weight: 600;
}
.update_btn_wrap {
  text-align: center;
}
 .success-message {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
}

/* Details page css  */
.detail_item h2 {
  margin: 0 0 30px;
  text-transform: uppercase;
}
.detail_img_grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(6, 1fr);
}
.detail_img_item img {
  width: 100%;
  display: block;
}
.detail_item {
  margin: 0 0 50px;
}

.projects .title {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 18px;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 5px;
}
.projects .title .count {
  font-weight: 300;
  text-transform: capitalize;
  padding-left: 0;
}
/** modal **/
.ReactModal__Overlay.ReactModal__Overlay--after-open.overlay {
  width: 80%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.ReactModal__Body--open .ReactModalPortal {
 height: 100%;
 width: 100%;
 position: fixed;
 top: 0;
 left: 0;
 background: rgba(0, 0, 0, 0.75);
 z-index: 999;
 margin: auto;
 bottom: 0;
 right: 0;
}
.close-modall {
  right: 0;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -10px;
  box-shadow: 0 0 13px;
}

/* add-image-title */

.title_img_inner {
  background: #1f2937;
  padding: 25px;
  border-radius: 10px;
}
.title_img_inner .form-group label {
  margin: 0 0 15px;
  font-size: 20px;
  line-height: normal;
  text-transform: capitalize;
  font-weight: 600;
  display: block;
}
.title_img_inner .form-group input {
  text-align: left;
  box-shadow: none;
  min-width: inherit;
  width: 100%;
  padding: 15px 20px;
  outline: none;
  border: none;
  border-radius: 10px;
  background: #fff;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  color: #000;
}
.title_img_inner .form-group input[type="file"] {
  width: fit-content;
}
.add_title_btn {
  text-align: center;
}
.add_title_btn button {
  background: #fff;
  border: 1px solid transparent;
  border-radius: 0;
  color: #000;
  display: inline-block;
  font-size: 15px;
  letter-spacing: .05px;
  padding: 10px 15px;
  font-weight: 600;
}
.title_img_inner .form-group {
  margin: 0 0 35px;
}
.title_img_inner .error-message, 
.title_img_inner .success-message {
  text-align: center;
  margin-top: 20px;
  font-weight: 600;
}

/** fadeInLeft **/
/* 
@-webkit-keyframes fadeInLeft {
  from {
      opacity:0;
      -webkit-transform: translatex(-10px);
      -moz-transform: translatex(-10px);
      -o-transform: translatex(-10px);
      transform: translatex(-10px);
  }
  to {
      opacity:1;
      -webkit-transform: translatex(0);
      -moz-transform: translatex(0);
      -o-transform: translatex(0);
      transform: translatex(0);
  }
}
@-moz-keyframes fadeInLeft {
  from {
      opacity:0;
      -webkit-transform: translatex(-10px);
      -moz-transform: translatex(-10px);
      -o-transform: translatex(-10px);
      transform: translatex(-10px);
  }
  to {
      opacity:1;
      -webkit-transform: translatex(0);
      -moz-transform: translatex(0);
      -o-transform: translatex(0);
      transform: translatex(0);
  }
}
@keyframes fadeInLeft {
  from {
      opacity:0;
      -webkit-transform: translatex(-100px);
      -moz-transform: translatex(-100px);
      -o-transform: translatex(-100px);
      transform: translatex(-100px);
  }
  to {
      opacity:1;
      -webkit-transform: translatex(0);
      -moz-transform: translatex(0);
      -o-transform: translatex(0);
      transform: translatex(0);
  }
}


/** fadeInLeft **/

/* @-webkit-keyframes fadeInRight {
  from {
      opacity:0;
      -webkit-transform: translatex(-10px);
      -moz-transform: translatex(-10px);
      -o-transform: translatex(-10px);
      transform: translatex(-10px);
  }
  to {
      opacity:1;
      -webkit-transform: translatex(0);
      -moz-transform: translatex(0);
      -o-transform: translatex(0);
      transform: translatex(0);
  }
}
@-moz-keyframes fadeInRight {
  from {
      opacity:0;
      -webkit-transform: translatex(-10px);
      -moz-transform: translatex(-10px);
      -o-transform: translatex(-10px);
      transform: translatex(-10px);
  }
  to {
      opacity:1;
      -webkit-transform: translatex(0);
      -moz-transform: translatex(0);
      -o-transform: translatex(0);
      transform: translatex(0);
  }
}
@keyframes fadeInRight {
  from {
      opacity:0;
      -webkit-transform: translatex(-100px);
      -moz-transform: translatex(-100px);
      -o-transform: translatex(-100px);
      transform: translatex(-100px);
  }
  to {
      opacity:1;
      -webkit-transform: translatex(0);
      -moz-transform: translatex(0);
      -o-transform: translatex(0);
      transform: translatex(0);
  }
} */ 

/***** Slide Right *****/

@keyframes slide-right {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0%;
  }
}

/***** Slide Right *****/

@keyframes slide-left {
  from {
    margin-left: 100%;
  }
  to {
    margin-left: 0%;
  }
}




/* Responsive Css */

@media (min-width:768px){
  .envisioning {
    height: 700px;
    overflow: hidden;
  }
  .social_media_page .bannerbox {
    bottom: 20%;
    top: unset;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px){
  .featured_work_slider .workbox_head {
      margin: 0px;
  }
  .social-media-steps-wraper {
    padding: 50px 30px;
  }
  .step-box-top {
      padding: 30px 20px;
  }
  .step-box-botm {
      padding: 20px;
  }
  .social-steps:last-child {
      padding-left: 40px;
  }
  .step-box {
      min-width: 220px;
  }
  .envisioning img{height: 100%;}
  .social_media_page .gallery_content1 {
    left: -65%;
  }
  .social_media_page .gallery_content h4 {
    font-size: 19px;
  }
}
@media (min-width:1025px) and (max-width:1280px){
  .social-media-steps-wraper {
    padding: 80px 50px;
  }
  .social-steps:last-child {
      padding-left: 40px;
  }
}

@media(max-width: 1280px){
  .project_list_wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media(max-width: 1024px){
.project_list_wrapper.plr-100 {
  padding-left: 50px;
  padding-right: 50px;
}
}
@media(max-width: 992px){
  .project_verticle_images .project_verticle_image:first-child {
    padding-right: 70px;
  }
  .project_verticle_images .project_verticle_image:last-child {
    padding-left: 70px;
  }
}

@media(max-width: 768px){
  .ptb-120 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .pb-120{
    padding-bottom: 80px;
  }
  .plr-100 {
    padding-left: 15px;
    padding-right: 15px;
  }
  h1, h2{
    font-size: 28px;
  }
  .showonmobile{
    display: block;
  }
  .hideonmobile {
      display: none;
    }
    header .header_top nav {
      display: none;
    }
    .header_contact {
        margin: 0 20px 0 auto;
    }
    .hamburger {
      display: block;
    }
  .featured_work_slider .swiper-slide .common_work_slide > img {
    max-width: 65%;
    margin: auto;
    left: 0;
    right: 0;
  }
  .featured_work_slider  .swiper-slide.swiper-slide-active {
    transform: scale(1);
  }
  .swiper-slide.swiper-slide-active .common_work_slide_text::after {
    width: calc(100% - 35%);
    background: #000;
    right: 0;
    margin: auto;
  }
  .common_work_slide {
    margin-bottom: 124px;
  }
  .featured_work_slider .swiper-slide.swiper-slide-active .common_work_slide_text {
    padding: 105px 10px 155px;
  }
  .featured_work_slider {
    padding-bottom: 0;
  }
  .home_gallery_block {
    padding-bottom: 50px;
  }
  .main_home_banner_content  .plr-100 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .home_banner_bottom  h1 {
    font-size: 24px;
    line-height: 30px;
  }
  .home_banner_bottom {
      padding: 0 32%;
      margin: 0 -70px;
  }
  .brand_direction {
    margin: 50px auto 0;
  }
  .brand_direction  .brand_logo {
    margin-top: 50px;
    margin-bottom: 50px;
  } 
  .brand-text-white {
    font-size: 50px;
    font-weight: normal;
}
  .about_top_banner .bannerbox {
    background: #00000040;
  }
  .contact_top_banner .bannerbox {
    box-shadow: 0px 22px 70px 4px #0000008F;
  }
  .about_top_banner .bannerbox h1,
  .contact_top_banner .bannerbox h1{
    font-size: 26px;
  }
  .contact_form_wrap .form-group.textareafld {
    padding: 0;
  }
  .about_content {
      margin: -180px auto 50px;
  }
  .social_media_page .what_we_do {
    margin-bottom: 50px;margin-top: 0;
  }
  .common_social_slider {
    padding-top: 100px;
  }  
  .font-18 {
    font-size: 16px;
    line-height: 23px;
  }
  .text_block {
    padding: 0 30px;
  }
  /* .envisioning {
    margin-top: 100px;
  } */
  .social_media_page .home_gallery_block {
    padding-bottom: 0;
  }
  .social_media_page h6.workhead {
    display: block;  
  }
  .featured_work_slider  .swiper-slide.swiper-slide-active:hover, 
  .seamless_booking_slider_wrapper .swiper-slide.swiper-slide-active {
    transform: none;
  }
  .seamless_booking_slider_wrapper .swiper {
    padding: 0;
  }
  .seamless_booking_slider_wrapper {
    margin-right: -15px;
  }
  .featured_work_slider.more_project_slider {
    padding-bottom: 100px;
  }
  .follow_milkbar_top_inner {
    gap: 20px;
    padding: 0 30px;
  }
  .follow_media_grid {
    gap: 4px;
  }
  .project_list_wrapper.plr-100 {
    padding-left: 50px;
    padding-right: 50px;
  }
  .project_list_wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
  .project_list_wrapper .common_work_slide {
    margin-bottom: 0px;
  }
  .services_tabs_panel {
      padding-left: 0;
    }
    .services_tabs_panel > h6 {
        text-align: center;
        margin: 0 0 50px;
    }
    .service_tab_images, .service_tab_items {
        flex: 0 0 auto;
        width: 100%;
    }
    .service_tab_images {
        padding: 0;
    }
    .service_desc {
        display: flex;
        gap: 20px;
        max-width: 300px;
        transition: all ease 0.3s;
        opacity: 0;
        visibility: hidden;
        display: none;
    }

    .service_tab_images .swiper-slide {
      flex-direction: column;
      text-align: center;
      opacity: 0.5;
    }
    .service_tab_images .swiper-slide.swiper-slide-active {
      opacity: 1;
    }
    .service_tab_item .service-name {
      text-align: center;
      width: 100%;
      position: relative;
    }
    .service_tab_item .service-name:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #a2bb42;
      transition: all ease 0.3s;
      opacity: 0;
  }
  .swiper-slide-active  .service_tab_item .service-name:after {
      opacity: 1;
    }
    .service_tab_item .service-name h3 {
        font-size: 18px;
        line-height: 22px;
    }
    .mobile_service_content {
      width: 100%;
      padding: 20px 10px 30px;
      opacity: 0;
      visibility: hidden;
      transition: all ease 0.3s;
    }
    .mobile_tab_content:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.3);
  }
  
    .swiper-slide-active .mobile_service_content {
      opacity: 1;
      visibility: visible;
    }
    .mobile_service_content .service-name h3 {
        opacity: 1;
    }
    .mobile_service_content .service_desc {
        display: block;
        opacity: 1;
        visibility: visible;
        margin: 0 auto;
    }
    .mobile_tab_content {
      width: 100%;
      height: 540px;
      position: relative;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
  }
    .services_tabs_panel .slider_nav {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        width: 100%;
        padding: 0 15px;
        z-index: 9;
    }
    .services_tabs_panel .slider_nav .service-swiper-custom-scrollbar {
        flex: 0 auto;
        position: relative;
        height: 2px;
        background: #F5F5F54D;
    }
    .services_tabs_panel .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
        background: #ffffff;
    }
    .follow_milkbar_bottom .swiper-slide {
        opacity: 0.3;
    }
    .follow_milkbar_bottom .swiper-slide.swiper-slide-active {
      opacity: 1;
    }
    .follow_media_image img {
          width: 100%;
    }
    .footer_wrapper {
        padding: 0 50px;
          gap: 0;
        flex-direction: column;
    }
    .footer_menu.reach_us ul {
        display: inline-flex;
        margin: 0 0 50px;
        border-bottom: 1px solid #ffffff69;
        padding: 0 0 50px;
    }
.social-media-steps-wraper {
  flex-wrap: wrap;
  padding: 50px 0 50px 15px;
  gap: 50px;
  justify-content: center;
}
.brand_page .home_banner_bottom {
  margin: 0 -40px 0 -32px;
}
.single-project-banner-bottm {
  gap: 35px;
}
.brand_page .what_we_do {
  margin: -17px 0 0;
  padding: 0 0 112px;
}
.social-media-steps h5 {
  margin: 0;
  text-align: center;
}
.social-steps {
  gap: 87px;
}
.step-box-top {
    padding: 30px;
}
.step-box-botm {
    padding: 20px 30px;
}
.social-steps{
  padding: 0;
  justify-content: center;
}
.social-steps:last-child {
  padding: 0;
  margin: 0;
}
.social-media-slider {
  padding: 87px 0 129px;
}
.social-media-slider .common_service_slide .social-media-slider-info {
  text-align: center;
  margin: 0 0 30px;
}
.social-media-slider .common_service_slide .single-brand-sec img {
  max-width: 75%;
}
.social-media-slider .swiper-slide-active .common_service_slide .single-brand-sec img {
  max-width: 85%;
}
.envisioning:before{
  content: '';
  position: absolute;
  left: 0;
  top:0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.40);
}
.social-media-slider .swiper-slide-active .common_service_slide {
  height: 432px;
  margin: 0;
}
.social-media-slider .common_service_slide {
  height: calc(358px - 100px);
  margin: 50px 0;
}
.social_media_page .main_home_banner_content {
  padding-top: 110px;
}
.social_media_page .bannerbox {
  height: calc(100% - 260px);
}
.social_media_page .main_home_banner::before {
  height: 288px;
}
.social_media_page .gallery_content1 {
  left: -32%;
}
.social_media_page .gallery_content h4 {
  font-size: 19px;
}


}

@media(max-width: 645px){
  .bannerbox {
    max-width: 270px;
    margin-top: 30px;
    height: calc(100% - 238px);
  }
  .main_home_banner picture img,
  .inner_page_banner picture img {
    height: auto;
  }
  .what_we_do {
    margin-top: -161px;
  }
  .option_types_radios input[type=radio] + label {
    padding: 16px 25px 15px;
  }
  .project_verticle_images {
    grid-template-columns: repeat(1, 1fr);
  }
  .project_verticle_images .project_verticle_image {
    max-width: 430px;
    width: 100%;
  }
  .project_verticle_images .project_verticle_image:last-child {
    margin-top: 50px;
    margin-left: auto;
  }
  .project_verticle_images .project_verticle_image:last-child img {
    margin-left: auto;
  }
  .social_media_page .gallery_content h4 {
    font-size: 19px;
  }
  .social_media_page .gallery_content1 {
    left: -15%;
    min-width: 300px;
  }
  .social_media_page .gallery_content2 {
    right: 10%;
    top: 170px;
    min-width: 300px;
  }
  .social_media_page .gallery_image_2 {
    height: 230px;
    transform: unset !important;
  }
  .social_media_page .gallery_image_3 {
    height: 224px;
    margin-top: 0;
    transform: unset !important;
  }
  .social_media_page .gallery_image_4 {
    margin-top: 137px;
    height: 140px;
  }
  .slide-right {
    animation: 3s slide-right;
  }
  .slide-left {
    animation: 4s slide-left;
  }
}

@media(max-width: 575px){
  .home_gallery_top .home_gallery_image.gallery_image_1 {
    width: 208px;
    height: 292px;
  }
  .gallery_image_2 {
    max-width: 185px;
    height: 229px;
  }
  .gallery_image_3 {
    max-width: 247px;
    height: 187px;
    margin-top: 183px;
  }
  .gallery_image_4 {
    width: 100px;
    height: 140px;
    margin-left: 75px;
  }
  .home_gallery_middle {
    margin-top: -145px;
  }
  .workbox_head {
    margin: 00px;
  }
  .common_social_slider {
    flex-direction: column-reverse;
  }
  .common_social_slider .swiper-slide:not(.swiper-slide-active) .social_slider_slide {
    height: calc(500px - 40px);
    margin-top: 40px;
    transition: all ease 0.3s;
  }
  .common_work_slide h2 {
    font-size: 26px;
  }
  .bannerbox {
    max-width: 270px;
  }
  .social_ltrslider_outer .social_ltrslider_left, .social_rtlslider_outer .social_rtlslider_left {
    padding: 20px 30px 0;
    text-align: center;
  }
  .common_social_slider .slider_scrollbar.swiper-scrollbar-horizontal {
    margin-left: auto;
    margin-right: auto;
  }
  .common_social_slider h3 {
    font-size: 26px;
  }
  .common_social_slider p {
    font-size: 16px;
    line-height: 23px;
  }
  .social_ltrslider_outer.common_social_slider1 {
    padding-top: 40px;
  }
  .common_social_slider > div {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .option_types_radios input[type=checkbox] + label {
      padding: 17px 25px 17px;
  }
  .project_list_wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
  .project_list_wrapper.plr-100 {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media(max-width: 480px){
  .common_service_slide_text:after {
      background: #000000;
      opacity: 50%;
  }
  .services_slider .slider_nav {
    margin-top: -60px;
    position: relative;
    z-index: 1;
    padding: 0 15px;
  }
  .home_banner_bottom {
    padding: 0;
  }
  .home_banner_bottom h1{
    font-size: 23px;
  }
  .common_social_slider {
    flex-direction: column-reverse;
  }  
}
@media(max-width: 375px){
  header > nav > ul > li:not(:last-child) {
    margin-right: 24px;
  }
  .home_banner_bottom {
    margin: 0 -45px;
  }
  .home_banner_bottom h1 {
    font-size: 22px;
  }
}
@media(max-width: 359px){
  .home_banner_bottom {
    margin: 0 -15px;
  }
  header > nav > ul > li:not(:last-child) {
    margin-right: 18px;
  }
}